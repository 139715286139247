import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as drawSvg from 'drawsvg';
import vueInlineSvg from 'vue-inline-svg'; 
import { configAuthInitial } from '@/services/auth'; // Import the configuration function





// Import components and functions
import VueDraggable from 'vuedraggable';
//import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate';
//import * as rules from '@vee-validate/rules';



//import {
//  applyPolyfills,
//  defineCustomElements
//} from '@aws-amplify/ui-components/loader'

import { VueCsvImportPlugin } from 'vue-csv-import';
import VuePdfEmbed from 'vue-pdf-embed';

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAdd,
  faXmarkCircle,
  faTrash,
  faShoppingCart,
  faLockOpen,
  faLocationCrosshairs,
  faBars,
  faArrowDown,
  faArrowUp,
  faCircleCheck,
  faCheck,
  faUser,
  faUserSlash,
  faEye,
  faEyeSlash,
  faEdit,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronRight,
  faSync,
  faPaperclip,
  faDownload,
  faUpload,
  faXmark,
  faSpinner,
  faCircleNotch,
  faCircleInfo,
  faLock,
  faFlag,
  faAngleDown,
  faAngleRight,
  faBarsStaggered,
  faFloppyDisk,
  faCircleChevronLeft,
  faCircleChevronRight,
  faListUl,
  faImage,
  faEllipsisVertical,
  faLocationDot,
  faMapLocationDot,
  faArrowTurnUp,
  faSquarePlus,
  faBookmark,
  faMap,
  faGem,
  faStar,
  faBook,
  faListCheck,
  faUnlock,
  faRoute,
  faMagnifyingGlass,
  faFilePen,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faArrowDownShortWide,
  faArrowUpShortWide,
  faTags,
  faTag,
  faFilter,
  faNoteSticky,
  faClone,
  faBoxArchive,
  faCoins,
  faEquals,
  faUpLong,
  faDownLong,
  faLightbulb,
  faArrowsRotate,
  faPaperPlane,
  faArrowUpLong,
  faBookOpenReader,
  faDumbbell,
  faClock,
  faBell,
  faLanguage,
  faCloudArrowUp,
  faClockRotateLeft,
  faRotateLeft,
  faTriangleExclamation,
  faRocket,
  faFlagCheckered,
  faHome,
  faUsers,
  faArrowLeft,
  faArrowUpFromBracket,
  faCircleDot as faCircleDotSolid,
  faMapLocationDot as faMapLocationDotSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCirclePlay,
  faCircleCheck as faCircleCheckRegular,
  faCalendar,
  faCalendarPlus,
  faCircleDot
} from '@fortawesome/free-regular-svg-icons';

//applyPolyfills().then(() => {
//  defineCustomElements(window)
//})

//console.log('localstore')
//const getCache = localStorage.getItem('commovis')

// Add icons to library
library.add(
    faAdd,
    faXmarkCircle,
    faTrash,
    faShoppingCart,
    faLockOpen,
    faLocationCrosshairs,
    faBars,
    faArrowDown,
    faArrowUp,
    faCircleCheck,
    faCheck,
    faUser,
    faUserSlash,
    faEye,
    faEyeSlash,
    faEdit,
    faPlus,
    faMinus,
    faChevronDown,
    faChevronRight,
    faSync,
    faPaperclip,
    faDownload,
    faUpload,
    faXmark,
    faSpinner,
    faCircleNotch,
    faCircleInfo,
    faLock,
    faFlag,
    faAngleDown,
    faAngleRight,
    faBarsStaggered,
    faFloppyDisk,
    faCircleChevronLeft,
    faCircleChevronRight,
    faListUl,
    faImage,
    faEllipsisVertical,
    faLocationDot,
    faMapLocationDot,
    faArrowTurnUp,
    faSquarePlus,
    faBookmark,
    faMap,
    faGem,
    faStar,
    faBook,
    faListCheck,
    faUnlock,
    faRoute,
    faMagnifyingGlass,
    faFilePen,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faArrowDownShortWide,
    faArrowUpShortWide,
    faTags,
    faTag,
    faFilter,
    faNoteSticky,
    faClone,
    faBoxArchive,
    faCoins,
    faEquals,
    faUpLong,
    faDownLong,
    faLightbulb,
    faArrowsRotate,
    faPaperPlane,
    faArrowUpLong,
    faBookOpenReader,
    faDumbbell,
    faClock,
    faBell,
    faLanguage,
    faCloudArrowUp,
    faClockRotateLeft,
    faRotateLeft,
    faTriangleExclamation,
    faRocket,
    faFlagCheckered,
    faHome,
    faUsers,
    faArrowLeft,
    faArrowUpFromBracket,
    faMapLocationDotSolid,
    faCircleDotSolid,

    faCirclePlay,
    faCircleCheckRegular,
    faCalendar,
    faCalendarPlus,
    faCircleDot,
);

async function initializeApp() {
  try {
    // Load tenant information first
    await store.dispatch('tenant/getTenant');

    // Configure Amplify
    await configAuthInitial();

    // Now create the app instance
    const app = createApp(App);



  app.use(store)
  app.use(router)
  app.use(VueCsvImportPlugin)

  app.component('drawSvg', drawSvg)

 app.component('VueDraggable', VueDraggable)
 app.component('vueInlineSvg', vueInlineSvg)
 app.component('VuePdfEmbed', VuePdfEmbed)


  app.component("font-awesome-icon", FontAwesomeIcon)

  app.mount('#app');

  } catch (error) {
    console.error('Error initializing the app:', error);
    // Handle initialization error (e.g., show error message)
  }
}

// Only register service worker in production
if (process.env.NODE_ENV === 'production') {
  require('./registerServiceWorker');
}

initializeApp(); // Start the initialization




