/* eslint-disable no-console */
import { register } from 'register-service-worker'

// Create a global flag to track if we've already shown the refresh notification
let refreshing = false;

// Handle the refresh when a new service worker takes over
if ('serviceWorker' in navigator && navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return;
    refreshing = true;
    window.location.reload();
  });
}

// Check if we're in PWA mode
const isPwa = () => {
  return window.matchMedia('(display-mode: standalone)').matches || 
         (window.navigator.standalone === true) || 
         document.referrer.includes('android-app://');
};

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      
      // Check for updates more frequently in PWA mode
      const updateInterval = isPwa() ? 15 * 60 * 1000 : 60 * 60 * 1000; // 15 min for PWA, 1 hour for browser
      
      setInterval(() => {
        registration.update();
      }, updateInterval);
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available.')
      
      // Create update notification element if it doesn't exist
      let updateDiv = document.getElementById('sw-update-notification');
      if (!updateDiv) {
        updateDiv = document.createElement('div');
        updateDiv.id = 'sw-update-notification';
        updateDiv.style.position = 'fixed';
        updateDiv.style.bottom = '20px';
        updateDiv.style.left = '50%';
        updateDiv.style.transform = 'translateX(-50%)';
        updateDiv.style.backgroundColor = '#4CAF50';
        updateDiv.style.color = 'white';
        updateDiv.style.padding = '16px';
        updateDiv.style.borderRadius = '4px';
        updateDiv.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)';
        updateDiv.style.zIndex = '9999';
        
        const message = document.createElement('span');
        message.textContent = 'A new version is available! ';
        
        const updateButton = document.createElement('button');
        updateButton.textContent = 'Update Now';
        updateButton.style.marginLeft = '10px';
        updateButton.style.padding = '8px 16px';
        updateButton.style.border = 'none';
        updateButton.style.borderRadius = '4px';
        updateButton.style.backgroundColor = 'white';
        updateButton.style.color = '#4CAF50';
        updateButton.style.cursor = 'pointer';
        
        updateButton.addEventListener('click', () => {
          // Skip waiting on the service worker and trigger the controllerchange event
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          updateDiv.remove();
        });
        
        updateDiv.appendChild(message);
        updateDiv.appendChild(updateButton);
        document.body.appendChild(updateDiv);
        
        // For PWA mode, auto-update after a short delay
        if (isPwa()) {
          setTimeout(() => {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            updateDiv.remove();
          }, 3000);
        }
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
      
      // Clear API caches when going offline to prevent stale data issues
      if ('caches' in window) {
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            if (cacheName.includes('api-')) {
              caches.delete(cacheName);
            }
          });
        });
      }
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
} else {
  // Development mode - disable service worker
  if ('serviceWorker' in navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
      }
    });
  }
  console.log('Service Worker disabled in development mode');
}