<template>
  <footer class="footer mt-auto py-3 bg-light">
    <div class="container container_wrapper">
      <span class="text-muted">
        Copyright © {{ year }} <span class="text-uppercase">commovis</span>® by Rupert Seewald GmbH & Co. KG</span>
        <span v-if="version" class="text-muted"> | Version: {{ version }}</span>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const year = new Date().getFullYear();
    const version = ref('');

    onMounted(async () => {
      try {
        const response = await fetch('/meta.json');
        if (!response.ok) {
          throw new Error('Failed to fetch version');
        }
        const data = await response.json();
        version.value = data.version;
      } catch (error) {
        console.error('Error loading version:', error);
      }
    });

    return {
      year,
      version,
    };
  },
};
</script>

<style scoped>
footer {
  font-size: 0.6em;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
