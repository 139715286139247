import axios from 'axios'
import { getAuthToken } from '@/services/auth'

const getDefaultState = () => {
    return {
        userNotifications: [],
    }
};

const state = getDefaultState();

const getters = {
    getUserNotifications: state => state.userNotifications,
    getPopupUserNotifications: state => state.userNotifications.filter((notification) => (notification.hasOwnProperty('Type') && notification.Type === 'Popup')),
}

const actions = {

    async fetchUserNotifications({commit, dispatch, rootState}) {
        // Try to refresh the token first to ensure we have a valid token
        try {
            await getAuthToken();
        } catch (error) {
            console.error('Failed to refresh token before fetching notifications:', error);
            // Continue anyway and let the API call handle any auth issues
        }

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {};

            // Add a timestamp to prevent browser/CDN caching
            const timestamp = new Date().getTime();
            config.params = { _t: timestamp };

            const response = await axios.post(`/v2/user-notifications/get`, payloadAPI, config);

            commit('PUSH_USER_NOTIFICATIONS', response.data.userNotificationMessages);
        }
        catch (err) {
            console.error('Error fetching user notifications:', err);
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to fetch user notifications'
            }, {root: true});
        }
    },

    async removeUserNotification({commit, dispatch, rootState}, userNotificationID) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            await axios.delete(`/v2/user-notifications/${userNotificationID}`, config);

            commit('DELETE_USER_NOTIFICATION', userNotificationID);
        }
        catch (err) {
            console.error('Error removing notification:', err);
        }
    },
}

const mutations = {
    PUSH_USER_NOTIFICATIONS: (state, userNotifications) => {
        state.userNotifications = userNotifications;
    },

    DELETE_USER_NOTIFICATION: (state, userNotificationID) => {
        state.userNotifications = state.userNotifications.filter((userNotification) => {
            return userNotification.ID !== userNotificationID;
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
