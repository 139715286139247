const getDay = (dateTime) => {
    const date = new Date(dateTime);
    return date.getDate().toString();
};

const getMonth = (dateTime) => {
    let month = new Date(dateTime).toLocaleString("en-US", { month: "short" });
    return month;
};

const get2DigitsYear = (year) => {
    return '\'' + year[year.length - 2] +  year[year.length - 1]
}

const getYear = (dateTime) => {
    let year = new Date(dateTime).getFullYear().toString();

    return get2DigitsYear(year);
};

const getHours = (dateTime) => {
    const date = new Date(dateTime);
    return date.getHours().toString().padStart(2, "0");
};

const getMinutes = (dateTime) => {
    const date = new Date(dateTime);
    return date.getMinutes().toString().padStart(2, "0");
};

const getTime = (dateTime) => {

    const fromHour = getHours(dateTime);
    const fromMinutes = getMinutes(dateTime);
    return `${fromHour}:${fromMinutes}`;

};

export { getMinutes, getHours, getTime, getDay, getMonth, getYear, get2DigitsYear };