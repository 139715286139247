import axios from 'axios';

const getDefaultState = () => {
    return {
        journeyTemplates: [],
        journeyReusableTemplates: [],
    }
};

const prepareJourneyStructureForSave = async function (JourneyID, Structure) {

    let contentNewImages = [];

    let journeyLinkedAssets = [];

    Structure.forEach((StructureItem) => {
        // check chapters for new images
        if (StructureItem.Type === 'Chapter') {

            if (StructureItem.hasOwnProperty('Units')) {

                //before save always eliminate the dummy last unit
                if (StructureItem.Units.length > 1) {
                    StructureItem.Units = StructureItem.Units.filter((unit) => !unit.hasOwnProperty('Temp'));
                }

                StructureItem.Units.forEach((Unit, indexUnit) => {

                    if (Unit.hasOwnProperty('LinkedAssets')) {

                        Unit.LinkedAssets.forEach((linkedAsset, indexLinkedAsset) => {
                            if (linkedAsset.Type === 'Attachment' && linkedAsset.hasOwnProperty('IsNew')) {

                                let fileObject = {
                                    name: linkedAsset.Name,
                                    ext: linkedAsset.Ext,
                                    type: linkedAsset.File_type,
                                    file: linkedAsset.File,
                                    folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                                }

                                Unit.LinkedAssets[indexLinkedAsset] = {
                                    Type: linkedAsset.Type,
                                    Name: `${linkedAsset.Name}.${linkedAsset.Ext}`,
                                    Label: linkedAsset.Label,
                                    IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                                    FolderName: `journey_reusable_templates/${JourneyID}/assets`
                                }

                                journeyLinkedAssets.push(fileObject);
                            }
                            if (linkedAsset.Type === 'MemoryCard') {
                                let memoryCardSaveObject = {
                                    Type: linkedAsset.Type,
                                    BatchNumber: linkedAsset.BatchNumber,
                                    MemoryCardNumber: linkedAsset.MemoryCardNumber,
                                    IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                                }
                                Unit.LinkedAssets[indexLinkedAsset] = memoryCardSaveObject;
                            }
                            if (linkedAsset.Type === 'ExpeditionLog') {
                                let expeditionLogSaveObject = {
                                    Type: linkedAsset.Type,
                                    ExpeditionLogNumber: linkedAsset.ExpeditionLogNumber,
                                    IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                                }
                                Unit.LinkedAssets[indexLinkedAsset] = expeditionLogSaveObject;
                            }
                        });
                    }

                    if (Unit.hasOwnProperty('Blocks')) {
                        Unit.Blocks.forEach((Block) => {
                            if (Block.hasOwnProperty('Elements')) {
                                Block.Elements.forEach((Element) => {
                                    if (Element.type === 'image' && Element.data.file.hasOwnProperty('isNew')) {

                                        let imageObject = {
                                            element_type: 'image',
                                            name: Element.data.file.name,
                                            ext: Element.data.file.ext,
                                            type: Element.data.file.type,
                                            file: Element.data.file.file,
                                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                                        }

                                        const imageName = `${Element.data.file.name}.${Element.data.file.ext}`;

                                        Element.data.file = {
                                            name: imageName
                                        }
                                        contentNewImages.push(imageObject);
                                    }

                                    if (Element.type === 'image' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                                        delete Element.data.file.url;
                                    }

                                    if (Element.type === 'reusableImage' && Element.data.file.hasOwnProperty('isNew')) {

                                        let reusableImageObject = {
                                            element_type: 'reusableImage',
                                            source_key: Element.data.file.source_key,
                                            file_name: Element.data.file.name,
                                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                                        }

                                        Element.data.file = {
                                            name: reusableImageObject.file_name
                                        }
                                        contentNewImages.push(reusableImageObject);
                                    }

                                    if (Element.type === 'reusableImage' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                                        delete Element.data.file.url;
                                    }

                                });
                            }
                        });
                    }
                });
            }
        }

        // convert periods to universal time
        // check events for modules with unlock time
        if (StructureItem.Type === 'Event') {

            if (StructureItem.Options.IsUnlock && StructureItem.Periods.Unlock !== null) {
                StructureItem.Periods.Unlock = new Date(StructureItem.Periods.Unlock).toISOString();
            }

            if (StructureItem.Options.IsMeeting && StructureItem.Periods.From !== null) {
                StructureItem.Periods.From = new Date(StructureItem.Periods.From).toISOString();
            }

            if (StructureItem.Options.IsMeeting && StructureItem.Periods.To !== null) {
                StructureItem.Periods.To = new Date(StructureItem.Periods.To).toISOString();
            }

            if (StructureItem.hasOwnProperty('LinkedAssets')) {

                StructureItem.LinkedAssets.forEach((linkedAsset, indexLinkedAsset) => {

                    if (linkedAsset.Type === 'Attachment' && linkedAsset.hasOwnProperty('IsNew')) {

                        let fileObject = {
                            name: linkedAsset.Name,
                            ext: linkedAsset.Ext,
                            type: linkedAsset.File_type,
                            file: linkedAsset.File,
                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                        }

                        StructureItem.LinkedAssets[indexLinkedAsset] = {
                            Type: linkedAsset.Type,
                            Name: `${linkedAsset.Name}.${linkedAsset.Ext}`,
                            Label: linkedAsset.Label,
                            IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                            FolderName: `journey_reusable_templates/${JourneyID}/assets`
                        }

                        journeyLinkedAssets.push(fileObject);
                    }

                    if (linkedAsset.Type === 'MemoryCard') {
                        let memoryCardSaveObject = {
                            Type: linkedAsset.Type,
                            BatchNumber: linkedAsset.BatchNumber,
                            MemoryCardNumber: linkedAsset.MemoryCardNumber,
                            IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                        }
                        StructureItem.LinkedAssets[indexLinkedAsset] = memoryCardSaveObject;
                    }
                    if (linkedAsset.Type === 'ExpeditionLog') {
                        let expeditionLogSaveObject = {
                            Type: linkedAsset.Type,
                            ExpeditionLogNumber: linkedAsset.ExpeditionLogNumber,
                            IsTreasureChestAsset: linkedAsset.IsTreasureChestAsset,
                        }
                        StructureItem.LinkedAssets[indexLinkedAsset] = expeditionLogSaveObject;
                    }
                });
            }

            if (StructureItem.hasOwnProperty('Details') && StructureItem.Details.hasOwnProperty('Elements') && StructureItem.Details.Elements.length > 0) {
                StructureItem.Details.Elements.forEach((Element) => {
                    if (Element.type === 'image' && Element.data.file.hasOwnProperty('isNew')) {

                        let imageObject = {
                            element_type: 'image',
                            name: Element.data.file.name,
                            ext: Element.data.file.ext,
                            type: Element.data.file.type,
                            file: Element.data.file.file,
                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                        }

                        const imageName = `${Element.data.file.name}.${Element.data.file.ext}`;

                        Element.data.file = {
                            name: imageName
                        }
                        contentNewImages.push(imageObject);
                    }

                    if (Element.type === 'image' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                        delete Element.data.file.url;
                    }

                    if (Element.type === 'reusableImage' && Element.data.file.hasOwnProperty('isNew')) {

                        let reusableImageObject = {
                            element_type: 'reusableImage',
                            source_key: Element.data.file.source_key,
                            file_name: Element.data.file.name,
                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                        }

                        Element.data.file = {
                            name: reusableImageObject.file_name
                        }
                        contentNewImages.push(reusableImageObject);
                    }

                    if (Element.type === 'reusableImage' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
                        delete Element.data.file.url;
                    }

                });
            }

            if (StructureItem.hasOwnProperty('Assignment')) {

                if (StructureItem.Assignment.hasOwnProperty('ID') && StructureItem.Assignment.ID !== null) {

                    // save images and reusable images from checklist task
                    if (StructureItem.Assignment.Type === 'Checklist') {

                        StructureItem.Assignment.Content.Tasks.forEach((Task) => {

                            if (Task.hasOwnProperty('Content') && Task.Content.hasOwnProperty('Elements') && Task.Content.Elements.length > 0) {

                                Task.Content.Elements.forEach((TaskElement) => {

                                    if (TaskElement.type === 'image' && TaskElement.data.file.hasOwnProperty('isNew')) {

                                        let imageObject = {
                                            element_type: 'image',
                                            name: TaskElement.data.file.name,
                                            ext: TaskElement.data.file.ext,
                                            type: TaskElement.data.file.type,
                                            file: TaskElement.data.file.file,
                                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                                        }

                                        const imageName = `${TaskElement.data.file.name}.${TaskElement.data.file.ext}`;

                                        TaskElement.data.file = {
                                            name: imageName
                                        }
                                        contentNewImages.push(imageObject);
                                    }

                                    if (TaskElement.type === 'image' && !TaskElement.data.file.hasOwnProperty('isNew') && TaskElement.data.file.hasOwnProperty('url')) {
                                        delete TaskElement.data.file.url;
                                    }

                                    if (TaskElement.type === 'reusableImage' && TaskElement.data.file.hasOwnProperty('isNew')) {

                                        let reusableImageObject = {
                                            element_type: 'reusableImage',
                                            source_key: TaskElement.data.file.source_key,
                                            file_name: TaskElement.data.file.name,
                                            folder_name: `journey_reusable_templates/${JourneyID}/assets`,
                                        }

                                        TaskElement.data.file = {
                                            name: reusableImageObject.file_name
                                        }
                                        contentNewImages.push(reusableImageObject);
                                    }

                                    if (TaskElement.type === 'reusableImage' && !TaskElement.data.file.hasOwnProperty('isNew') && TaskElement.data.file.hasOwnProperty('url')) {
                                        delete TaskElement.data.file.url;
                                    }

                                });
                            }
                        });

                    }

                }

            }

        }

    });

    return {
        contentNewImages: contentNewImages,
        journeyLinkedAssets: journeyLinkedAssets,
    };
};

const checkOverviewForNewImages = async function(JourneyID, Overview) {

    let overviewNewImages = [];

    Overview.Elements.forEach((Element) => {

        if (Element.type === 'image' && Element.data.file.hasOwnProperty('isNew')) {

            let imageObject = {
                element_type: 'image',
                name: Element.data.file.name,
                ext: Element.data.file.ext,
                type: Element.data.file.type,
                file: Element.data.file.file,
                folder_name: `journey_reusable_templates/${JourneyID}/assets`,
            }

            const imageName = `${Element.data.file.name}.${Element.data.file.ext}`;

            Element.data.file = {
                name: imageName
            }
            overviewNewImages.push(imageObject);
        }

        if (Element.type === 'image' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
            delete Element.data.file.url;
        }

        if (Element.type === 'reusableImage' && Element.data.file.hasOwnProperty('isNew')) {

            let reusableImageObject = {
                element_type: 'reusableImage',
                source_key: Element.data.file.source_key,
                file_name: Element.data.file.name,
                folder_name: `journey_reusable_templates/${JourneyID}/assets`,
            }

            Element.data.file = {
                name: reusableImageObject.file_name
            }
            overviewNewImages.push(reusableImageObject);
        }

        if (Element.type === 'reusableImage' && !Element.data.file.hasOwnProperty('isNew') && Element.data.file.hasOwnProperty('url')) {
            delete Element.data.file.url;
        }
    })

    return overviewNewImages;

};

const state = getDefaultState();

const getters = {
    getJourneyTemplates: state => state.journeyTemplates,
    getJourneyReusableTemplates: state => state.journeyReusableTemplates,
}

const actions = {

    async list({ commit, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey-reusable-templates/list', payload, config);

            commit('SET_JOURNEY_TEMPLATES', response.data.journeyTemplates);

        }
        catch (err) {
            // throw new Error(err);
        }
    },

    async listReusableTemplates({ commit, rootState}) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };
            let payload = {

            };

            const response = await axios.post('/v2/journey-reusable-templates/list-reusable-templates', payload, config);

            commit('SET_JOURNEY_REUSABLE_TEMPLATES', response.data.journeyReusableTemplates);

        }
        catch (err) {
            // throw new Error(err);
        }
    },

    async addToPendingReusableTemplates({ commit, dispatch, rootState }, payload) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                "JourneyID": payload.ID,
                "Name": payload.Name,
                "Description": payload.Description,
                "AuthorID": payload.AuthorID,
                "CategoryID": payload.CategoryID,
                "Language": payload.Language,
                "Picture": payload.Picture,
                "Overview": payload.Overview,
                "Structure": payload.Structure
            }

            await axios.post('/v2/journey-reusable-templates/add-to-pending', payloadAPI, config);

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey successfully added to pending reusable templates'
            }, {root: true});

        }
        catch (err) {

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey failed to be added to pending reusable templates'
            }, {root: true});
        }
    },

    async updateJourneyReusableTemplate({ commit, dispatch, rootState }, updatedJourneyTemplate) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            // save overview images to S3
            let overviewNewImages = await checkOverviewForNewImages(updatedJourneyTemplate.ID, updatedJourneyTemplate.Overview);

            if (overviewNewImages.length > 0) {
                for (const overviewNewImage of overviewNewImages) {
                    if (overviewNewImage.element_type === 'image') {
                        await dispatch('file/uploadFile', overviewNewImage, {root: true});
                    }
                    if (overviewNewImage.element_type === 'reusableImage') {
                        await dispatch('file/copyFileBetweenS3Folders', overviewNewImage, {root: true});
                    }
                }
            }

            // save blocks images to S3
            let {contentNewImages, journeyLinkedAssets} = await prepareJourneyStructureForSave(updatedJourneyTemplate.ID, updatedJourneyTemplate.Structure);

            if (contentNewImages.length > 0) {
                for (const contentNewImage of contentNewImages) {
                    if (contentNewImage.element_type === 'image') {
                        await dispatch('file/uploadFile', contentNewImage, {root: true});
                    }
                    if (contentNewImage.element_type === 'reusableImage') {
                        await dispatch('file/copyFileBetweenS3Folders', contentNewImage, {root: true});
                    }
                }
            }

            if (journeyLinkedAssets.length > 0) {
                for (const unitAsset of journeyLinkedAssets) {
                    await dispatch('file/uploadFile', unitAsset, {root: true});
                }
            }

            let journeyPayload = {
                "AuthorID": updatedJourneyTemplate.AuthorID,
                "Name": updatedJourneyTemplate.Name,
                "Description": updatedJourneyTemplate.Description,
                "CategoryID": updatedJourneyTemplate.CategoryID,
                "Language": updatedJourneyTemplate.Language,
                "Picture": updatedJourneyTemplate.Picture,
                "Overview": updatedJourneyTemplate.Overview,
                "Structure": updatedJourneyTemplate.Structure,
                "Reusable": updatedJourneyTemplate.Reusable,
            };

            if (updatedJourneyTemplate.hasOwnProperty('ExistingAssetsToDelete') && updatedJourneyTemplate.ExistingAssetsToDelete.length > 0) {
                journeyPayload.ExistingAssetsToDelete = updatedJourneyTemplate.ExistingAssetsToDelete;

                for (let ExistingAssetToDelete of updatedJourneyTemplate.ExistingAssetsToDelete) {
                    let payloadS3 = {
                        folder_name: `journey_reusable_templates/${updatedJourneyTemplate.ID}/assets`,
                        file_name: ExistingAssetToDelete
                    }
                    await dispatch('file/deleteFile', payloadS3, {root: true});
                }
            }

            let isPictureUpdated = (updatedJourneyTemplate.hasOwnProperty('PictureUpdated'));
            let picture_old = updatedJourneyTemplate.Picture;

            if (isPictureUpdated) {
                picture_old = updatedJourneyTemplate.Picture;
                journeyPayload.Picture =`${updatedJourneyTemplate.PictureUpdated.name}.${updatedJourneyTemplate.PictureUpdated.ext}`;
            }

            // save updated Journey
            const response = await axios.put(`/v2/journey-reusable-template/${updatedJourneyTemplate.ID}`, journeyPayload, config);

            // save Journey main Picture if updated
            if (isPictureUpdated) {
                let journeyPicturePayload = updatedJourneyTemplate.PictureUpdated;
                journeyPicturePayload.folder_name = `journey_reusable_templates/${updatedJourneyTemplate.ID}`;
                await dispatch('file/uploadFile', journeyPicturePayload, {root: true});
                // get main Picture download pre-signed url
                response.data.updatedJourneyTemplate.PictureURL = await dispatch('file/getFile', {
                    file_name: response.data.updatedJourneyTemplate.Picture,
                    folder_name: `journey_reusable_templates/${response.data.updatedJourneyTemplate.ID}`
                }, {root: true});

                // delete Journey old main Picture
                if (picture_old !== null) {
                    const delete_payload = {
                        folder_name: `journey_reusable_templates/${updatedJourneyTemplate.ID}`,
                        file_name: picture_old
                    }
                    await dispatch('file/deleteFile', delete_payload, {root: true});
                }
            }

            // response.data.updatedJourneyTemplate.IsCurrentUserAuthor = updatedJourneyTemplate.IsCurrentUserAuthor;
            // response.data.updatedJourneyTemplate.IsCurrentUserParticipant = updatedJourneyTemplate.IsCurrentUserParticipant;

            commit('UPDATE_JOURNEY_REUSABLE_TEMPLATE', response.data.updatedJourneyTemplate);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey template successfully updated'
            }, {root: true});

            commit('loading/setLoading', false, { root: true });

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey template failed to be updated'
            }, {root: true});

            commit('loading/setLoading', false, { root: true });

            throw new Error(err);
        }

    },

    async updateJourneyTemplateStatus({ commit, dispatch, rootState }, payload) {

        commit('loading/setLoading', true, { root: true });

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {

            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            };

            let payloadAPI = {
                "ID": payload.ID,
                "Reusable": payload.Reusable
            }

            await axios.post('/v2/journey-reusable-templates/update-template-status', payloadAPI, config);

            commit('UPDATE_JOURNEY_TEMPLATE_STATUS', payloadAPI);

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey reusable template status successfully updated'
            }, {root: true});

        }
        catch (err) {

            commit('loading/setLoading', false, { root: true });

            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey reusable template status failed to be updated'
            }, {root: true});
        }
    },

    async updateFromTemplateManagement({ commit, dispatch, rootState }, updatedJourneyTemplate) {
        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "TemplateID": updatedJourneyTemplate.TemplateID,
                "AuthorID": updatedJourneyTemplate.AuthorID,
                "Language": updatedJourneyTemplate.Language
            }

            const response = await axios.put(`/v2/journey-reusable-template/update-from-journey-template-management/${updatedJourneyTemplate.TemplateID}`, payloadAPI, config);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey template successfully updated'
            }, {root: true});
            //
            // return response.data.updatedJourneyTemplate;
        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Journey template failed to be updated'
            }, {root: true});
            throw new Error(err);
        }
    },


    async deleteJourneyTemplate({ commit, dispatch, rootState }, payload) {

        axios.defaults.baseURL = rootState.tenant.tenantinfo.baseAPI;

        try {
            let config = {
                headers: {
                    Authorization: rootState.auth.bearer
                },
            }

            let payloadAPI = {
                "JourneyTemplateID": payload.JourneyTemplateID,
            }

            await axios.post(`/v2/journey-reusable-templates/delete-template`, payloadAPI, config);

            commit('DELETE_JOURNEY_TEMPLATE', payloadAPI);

            dispatch('notifications/addNotification', {
                type: 'success',
                message: 'Journey template deleted successfully'
            }, {root: true});

        }
        catch (err) {
            dispatch('notifications/addNotification', {
                type: 'error',
                message: 'Failed to delete the journey'
            }, {root: true});
            throw new Error(err);
        }
    },

}

const mutations = {

    SET_JOURNEY_TEMPLATES(state, journeyTemplates) {
        state.journeyTemplates = journeyTemplates;
    },

    SET_JOURNEY_REUSABLE_TEMPLATES(state, journeyReusableTemplates) {
        state.journeyReusableTemplates = journeyReusableTemplates;
    },

    UPDATE_JOURNEY_TEMPLATE_STATUS(state, payload) {
        const index = state.journeyTemplates.findIndex((journeyTemplate) => journeyTemplate.ID === payload.ID);

        if (index !== -1) {
            state.journeyTemplates[index].Reusable = payload.Reusable;
        }
    },

    UPDATE_JOURNEY_REUSABLE_TEMPLATE(state, payload) {

        const indexJourneyTemplate = state.journeyTemplates.findIndex((journeyTemplate) => journeyTemplate.ID === payload.ID);
        if (indexJourneyTemplate !== -1) {
            for (let key in payload) {
                if (key in state.journeyTemplates[indexJourneyTemplate]) {
                    state.journeyTemplates[indexJourneyTemplate][key] = payload[key];
                }
            }
        }

        const indexJourneyReusableTemplate = state.journeyReusableTemplates.findIndex((journeyReusableTemplate) => journeyReusableTemplate.ID === payload.ID);
        if (indexJourneyReusableTemplate !== -1) {
            for (let key in payload) {
                if (key in state.journeyReusableTemplates[indexJourneyReusableTemplate]) {
                    state.journeyReusableTemplates[indexJourneyReusableTemplate][key] = payload[key];
                }
            }
        }

    },

    DELETE_JOURNEY_TEMPLATE(state, payload) {
        state.journeyTemplates = state.journeyTemplates.filter((journeyTemplate) => journeyTemplate.ID !== payload.JourneyTemplateID);
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState());
      },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
