<template>
  <transition name="fade">
    <div v-if="visible" class="modal-overlay">
      <div class="modal-content">

        <div class="title-wrapper">

          <img :src="bell" class="bell" alt="bell">

          <h2>Hey {{ getUserName }},</h2>

        </div>

        <div class="message">
          <span>Just a quick reminder that the tasks for quiz </span><span style="font-weight: bold;"> {{ notification.Content.QuizTitle }} </span><span> from assignment </span><span style="font-weight: bold;"> {{ notification.Content.StructureItemName }} </span><span> in journey </span><span style="font-weight: bold;"> {{ notification.Content.JourneyName }} </span><span> is due on:</span>
        </div>

        <div class="calendar-wrapper">
          <div class="calendar">
            <div class="header" :style="{ 'background-color': store.state.tenant.tenantinfo.primarycolor }">
              <div class="month">{{ getMonth(notification.Content.DueDate) }}</div>
            </div>
            <div class="date">{{ getDay(notification.Content.DueDate) }}</div>
          </div>
        </div>

        <p class="message">Make sure you get it done before then. <br>Good luck, you've got this! 😊</p>
<!--        <p class="message">Good luck, you've got this! 😊</p>-->

        <button class="close-btn" :style="{ 'background-color': store.state.tenant.tenantinfo.primarycolor }" @click="close()">
<!--          <font-awesome-icon icon="check"/>-->
          OK
        </button>

      </div>

    </div>
  </transition>
</template>

<script>

import {computed, ref} from "vue";
import {useStore} from "vuex";
import {getTime, getDay, getMonth, getYear} from "@/helpers/calendar.js";
import bell from "@/assets/icons/bell.png";

export default {

  name: 'PopupNotification',

  props: {
    notification: {
      type: Object,
      required: true,
    },
    // message: {
    //   type: String,
    //   required: true
    // }
  },

  setup(props) {

    const store = useStore();
    const visible = ref(true);

    const getUserName = computed(() => {
      return store.state.auth.userInfo.given_name
    });

    const show = () => {
      visible.value = true;
    };

    const close = async () => {
      await store.dispatch('userNotifications/removeUserNotification', props.notification.ID);
      visible.value = false;
    };

    return {
      store,
      visible,
      getUserName,
      bell,
      show,
      close,
      getMonth,
      getDay,
    }
  },

}

</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  outline: 0;
  gap: 10px;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .bell {
      width: 25px;
      animation: bellshake 1s cubic-bezier(.36,.07,.19,.97) both;
      backface-visibility: hidden;
      transform-origin: top right;
      animation-iteration-count: infinite;
      //&:hover, &:focus {
      //  animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
      //  backface-visibility: hidden;
      //  transform-origin: top right;
      //}
    }

    @keyframes bellshake {
      0% { transform: rotate(0); }
      15% { transform: rotate(5deg); }
      30% { transform: rotate(-5deg); }
      45% { transform: rotate(4deg); }
      60% { transform: rotate(-4deg); }
      75% { transform: rotate(2deg); }
      85% { transform: rotate(-2deg); }
      100% { transform: rotate(0); }
    }

    h2 {
      margin: 0;
    }
  }

  .message {
    margin: 0;
    font-size: 18px;
  }

  .calendar-wrapper {
    display: flex;
    justify-content: center;

    .calendar {
      width: 100px;
      height: 75px;
      background: #fff;
      border-radius: 10px;
      text-align: center;
      position: relative;
      -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    }

    .calendar .header {
      background-color: var(--primary-color);
      color: white;
      padding: 3px 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
      //line-height: 15px;
      display: flex;
      justify-content: center;
      align-items: start;
      gap: 5px
    }
    .header {
      .month {
        font-size: 20px;

      }
      .year {
        font-size: 13px;
      }
    }

    .calendar .date {
      //position: absolute;
      //top: 70%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  }

  .close-btn {
    font-size: 18px;
    //margin: 10px auto 10px auto;
    padding: 5px 10px;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    margin: 10px auto 0 auto;
  }

}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>